const AdvertisingChannelIcons = {
  search: 'search',
  facebook: 'facebook',
  display: 'display',
  discovery: 'display',
  shopping: 'shopping',
  waze: 'waze',
  dealercomrtb: 'display',
  ddcrtbtopic: 'display',
  displaygdn: 'display',
  googleshopping: 'shopping',
  amazonadvertising: 'iphone',
  snapchat: 'display',
  twitter: 'search',
  hotel: 'search',
  video: 'search',
  youtube: 'display',
  ddcrtbingested: 'display',
  ddcrtbaudiencetargeting: 'display',
  ddcrtbpremium: 'display',
  ddcrtbremarketing: 'display',
  ddcrtbvideo: 'display',
  googlelocalcampaigns: 'mapMarker',
  local: 'mapMarker',
  appStore: 'search',
  performancemax: 'performance-max',
  pinterest: 'imageLandscape',
  dv360display: 'display',
  dv360video: 'display',
  amazonads: 'shopping',
  dspdisplay: 'display',
  dspvideo: 'display',
  get: function (channelLabel) {
    return this?.[channelLabel?.replace(/[^a-zA-Z0-9]/g, '')?.toLowerCase()]
  }
}

export default AdvertisingChannelIcons
